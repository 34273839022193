import React from 'react';
import { Link } from 'react-router-dom';

import instagramIcon from "../../img/instagram.svg";
import youtubeIcon from "../../img/youtube.svg";
import facebookIcon from "../../img/facebook.svg";
import linkedinIcon from "../../img/linkedin.svg";
import twitterIcon from "../../img/tweter.svg";

const Footer = () => {

   const linkItems = [
     {
       id: 1,
       title: "About us",
       path: "https://crosslinker.us",
     },
     {
       id: 2,
       title: "Contact ",
       path: "https://crosslinker.us/contact",
     },
     {
       id: 3,
       title: "Jobs",
       path: "#",
     },
     {
       id: 4,
       title: "Privacy Policy",
       //  path: "/privacy-policy",
       path: "https://crosslinker.us/privacy-policy",
     },
     {
       id: 5,
       title: "Terms of Use",
       path: "https://crosslinker.us/terms-and-conditions",
     },
   ];

   const socialLinks = [
     {
       id: 1,
       imgUrl: twitterIcon,
       path: "https://x.com/CrosslinkerAi",
     },
     {
       id: 2,
       imgUrl: instagramIcon,
       path: "https://www.instagram.com/crosslinker.usa/",
     },
     {
       id: 3,
       imgUrl: youtubeIcon,
       path: "https://www.youtube.com/@crosslinker4948/about",
     },
     {
       id: 4,
       imgUrl: facebookIcon,
       path: "https://www.facebook.com/crosslinker.us",
     },
     {
       id: 5,
       imgUrl: linkedinIcon,
       path: "https://www.linkedin.com/in/crosslinker",
     },
   ];
   return (
     //   <div>
     //       <footer className="footer footer-center p-10 bg-gray-800 text-gray-200 rounded">
     //           <div className="grid grid-flow-col gap-4">
     //               <Link to={"/about-us"} className="link link-hover text-[#FFCC40]">About us</Link>
     //               <Link to={"/contact-us"} className="link link-hover text-[#FFCC40]">Contact</Link>
     //               <Link to={"/jobs"} className="link link-hover text-[#FFCC40]">Jobs</Link>
     //               <Link to={"/privacy-policy"} className="link link-hover text-[#FFCC40]">Privacy Policy</Link>
     //               <Link to={"/terms-of-use"} className="link link-hover text-[#FFCC40]">Terms and Conditions</Link>
     //           </div>
     //           <div>
     //               <div className="grid grid-flow-col gap-4">
     //                   <a href='https://twitter.com/CrosslinkerAi'
     //                      target='_blank'
     //                      rel="noreferrer"
     //                   ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg></a>
     //                   <a href='https://www.youtube.com/@crosslinker4948/about'
     //                      target='_blank'
     //                      rel="noreferrer"
     //                   ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
     //                   <a href='https://www.facebook.com/crosslinker.us'
     //                      target='_blank'
     //                      rel="noreferrer"
     //                   ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
     //               </div>
     //           </div>
     //           <div>
     //               <p className="text-[#FFCC40]">Copyright © 2023 - All right reserved by CROSSLINKER USA</p>
     //           </div>
     //       </footer>
     //   </div>

     <div className=" h-140 bg-gray-900 flex-shrink-0 flex flex-col justify-center items-center gap-2 footer-container space-y-2">
       <ul className=" w-full flex flex-col lg:flex-row justify-center lg:items-center gap-0 md:gap-[6px] lg:gap-[6px] mobileMenu">
         {linkItems.map((item) => (
           <li
             key={item.id}
             class="md:ml-0 lg:md-0 ml-2 duration-300 hover:underline sm:text-sm text-xs transition text-[#FFF]"
           >
             <Link to={item.path}>{item.title}</Link>,
           </li>
         ))}
       </ul>
       <div className="w-full flex flex-col justify-center items-center gap-2">
         <ul className="w-full flex justify-center lg:justify-center items-center gap-2">
           {socialLinks.map((item) => (
             <li key={item.id} className="text-[#FFF]">
               <a href={item.path} target="_blank" rel="noopener noreferrer">
                 <img
                   src={item.imgUrl}
                   alt="Social Icon"
                   className="text-[#FFF]"
                 />
               </a>
             </li>
           ))}
         </ul>
       </div>
       <p className="text-xs  font-bold cursor-pointer md:text-sm-800">
         Copyright© 2022-2024 Crosslinker USA, All Rights Reserved
       </p>
     </div>
   );
};

export default Footer;